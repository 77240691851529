"use strict";

// Navigation.
$(".burger").on("click", function() {
  $(this).toggleClass("burger--animate");
  $(".header__right").slideToggle();
  //$(".header__right").toggleClass("header__right--open");
});

$(document).ready(function() {
  // Homepage Hero Slider
  $(".slider-hero__slider").slick({
    infinite: true,
    dots: false,
    arrows: true,
    prevArrow: $(".slider-button--prev"),
    nextArrow: $(".slider-button--next")
  });
});

// Back to top

$(".footer__top").click(function() {
  $("html, body").animate(
    {
      scrollTop: 0
    },
    750
  );
});

$(".scroll-down").click(function() {
  $([document.documentElement, document.body]).animate(
    {
      scrollTop: $("#main").offset().top
    },
    750
  );
});

/**
 * Meet the Team.
 */

// Teachers modal.

$(".teachers").on("click", function(event) {
  if ($(event.target).hasClass("teacher__bio")) {
    var teacher = $(event.target).closest(".teacher");

    var data = {};
    data.name = teacher.find(".teacher__name").text();
    data.class = teacher.find(".teacher__class").text();
    data.bio = teacher.attr("data-bio");
    data.classSlug = teacher.attr("data-class");

    //console.log(data);
    // open & set data
    modal.open();
    modal.setData(data);
  }
});

function Modal() {
  this.modal = $(".modal");

  this.open = function() {
    this.modal.removeClass("modal--close");
    this.modal.addClass("modal--open");
  };
  this.close = function() {
    this.modal.removeClass("modal--open");
    this.modal.addClass("modal--close");
  };
  this.setData = function(data) {
    var modalTitle = this.modal.find(".modal__title");
    modalTitle.attr("class", "");
    modalTitle.addClass("modal__title modal__title--" + data.classSlug);

    var teacherName = this.modal.find(".modal__title");
    var teacherClass = this.modal.find(".modal__text--class");
    teacherClass.attr("class", "modal__text--class");
    teacherClass.addClass("modal__text--" + data.classSlug);

    var teacherBio = this.modal.find(".modal__text--bio");

    teacherName.text(data.name);
    teacherClass.text(data.class);
    teacherBio.text(data.bio);
  };
}

var modal = new Modal();

$(".modal").on("click", function(event) {
  event.preventDefault();

  if (
    $(event.target).hasClass("modal__exit") ||
    $(event.target).hasClass("modal") ||
    $(event.target).hasClass("modal__icon")
  ) {
    modal.close();
  }
});

// Polyfill.
$(function() {
  objectFitImages();
});

/**
 * Resources/Links Sliders.
 */

$(".resources-slider--1").slick({
  infinite: true,
  dots: false,
  arrows: true,
  slidesToShow: 3,
  prevArrow: $(".slider-button--1"),
  nextArrow: $(".slider-button--2"),
  responsive: [
    {
      breakpoint: 1224,
      settings: {
        slidesToShow: 1
      }
    }
  ]
});

$(".resources-slider--2").slick({
  infinite: true,
  dots: false,
  arrows: true,
  slidesToShow: 3,
  prevArrow: $(".slider-button--3"),
  nextArrow: $(".slider-button--4"),
  responsive: [
    {
      breakpoint: 1224,
      settings: {
        slidesToShow: 1
      }
    }
  ]
});

$(".resources-slider--3").slick({
  infinite: true,
  dots: false,
  arrows: true,
  slidesToShow: 3,
  prevArrow: $(".slider-button--5"),
  nextArrow: $(".slider-button--6"),
  responsive: [
    {
      breakpoint: 1224,
      settings: {
        slidesToShow: 1
      }
    }
  ]
});

// Curriculum.

$(".faq").on("click", function() {
  if (
    $(this)
      .find(".faq__lower")
      .is(":visible")
  ) {
    $(this)
      .find(".faq__lower")
      .slideUp();
    $(this)
      .find(".faq__icon")
      .removeClass("faq__icon--active");
  } else {
    $(".faq__lower").slideUp();
    $(".faq__icon").removeClass("faq__icon--active");
    $(this)
      .find(".faq__lower")
      .slideToggle();
    $(this)
      .find(".faq__icon")
      .toggleClass("faq__icon--active");
  }
});

// Classes slider.

$(document).ready(function() {
  $(".classes__slider--0").slick({
    infinite: true,
    dots: false,
    arrows: true,
    slidesToShow: 1,
    prevArrow: $(".slider-button--prev--0"),
    nextArrow: $(".slider-button--next--0")
  });

  $(".classes__slider--1").slick({
    infinite: true,
    dots: false,
    arrows: true,
    slidesToShow: 1,
    prevArrow: $(".slider-button--prev--1"),
    nextArrow: $(".slider-button--next--1")
  });

  $(".classes__slider--2").slick({
    infinite: true,
    dots: false,
    arrows: true,
    slidesToShow: 1,
    prevArrow: $(".slider-button--prev--2"),
    nextArrow: $(".slider-button--next--2")
  });

  $(".classes__slider--3").slick({
    infinite: true,
    dots: false,
    arrows: true,
    slidesToShow: 1,
    prevArrow: $(".slider-button--prev--3"),
    nextArrow: $(".slider-button--next--3")
  });

  $(".classes__slider--4").slick({
    infinite: true,
    dots: false,
    arrows: true,
    slidesToShow: 1,
    prevArrow: $(".slider-button--prev--4"),
    nextArrow: $(".slider-button--next--4")
  });

  $(".classes__slider--5").slick({
    infinite: true,
    dots: false,
    arrows: true,
    slidesToShow: 1,
    prevArrow: $(".slider-button--prev--5"),
    nextArrow: $(".slider-button--next--5")
  });
});

// Lightbox

function Lightbox() {
  this.lightbox = $(".lightbox");
  this.image = $(".lightbox__image");

  this.open = function(src) {
    this.lightbox.removeClass("lightbox--close");
    this.lightbox.addClass("lightbox--open");
    this.image.attr("src", src);
  };
  this.close = function() {
    this.lightbox.removeClass("lightbox--open");
    this.lightbox.addClass("lightbox--close");
  };
  this.setData = function(data) {
    var lightboxTitle = this.lightbox.find(".lightbox__title");
    lightboxTitle.attr("class", "");
    lightboxTitle.addClass(
      "lightbox__title lightbox__title--" + data.classSlug
    );

    var teacherName = this.lightbox.find(".lightbox__title");
    var teacherClass = this.lightbox.find(".lightbox__text--class");
    teacherClass.attr("class", "lightbox__text--class");
    teacherClass.addClass("lightbox__text--" + data.classSlug);

    var teacherBio = this.lightbox.find(".lightbox__text--bio");

    teacherName.text(data.name);
    teacherClass.text(data.class);
    teacherBio.text(data.bio);
  };
}

$(".lightbox").on("click", function(event) {
  event.preventDefault();

  if (
    $(event.target).hasClass("lightbox__exit") ||
    $(event.target).hasClass("lightbox") ||
    $(event.target).hasClass("lightbox__icon")
  ) {
    lightbox.close();
  }
});

var lightbox = new Lightbox();

var images = document.querySelectorAll(".lightbox-trigger");

for (var i = 0; i < images.length; i++) {
  var src = images[i].src;

  images[i].addEventListener("click", function() {
    lightbox.open(src);
  });
}

// Back to classes

$(".back-to-classes").on("click", function(event) {
  event.preventDefault();
  history.go(-1);
});

//js--same-1

// function setHeights(selector) {
//   var width = $(window).width();

//   if (width <= 1024) {
//     console.log("ret");
//     return;
//   }

//   var highestBox = 0;

//   $(selector).each(function() {
//     if ($(this).height() > highestBox) {
//       highestBox = $(this).height();
//     }

//     $(this).height(highestBox);
//   });
// }

// $(document).ready(function() {
//   setHeights(".js--same-1");
//   setHeights(".js--same-2");
//   setHeights(".js--same-3");
// });

// $(window).resize(function() {
//   setHeights(".js--same-1");
//   setHeights(".js--same-2");
//   setHeights(".js--same-3");
// });

if (navigator.userAgent.match(/Trident\/7\./)) {
  // if IE
  $("body").on("mousewheel", function() {
    // remove default behavior
    event.preventDefault();

    //scroll without smoothing
    var wheelDelta = event.wheelDelta;
    var currentScrollPosition = window.pageYOffset;
    window.scrollTo(0, currentScrollPosition - wheelDelta);
  });
}
